import * as React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "components/PageLayout";
import { Section } from "components/layout";
import { H1 } from "components/typography";
import SEO from "components/Seo";

const ContactInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 3rem;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, auto);
    justify-content: center;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.div`
  margin-right: 2rem;
  width: 8rem;
  height: 8rem;

  @media (max-width: 600px) {
    width: 4rem;
    height: 4rem;
  }
`;

const Text = styled.span`
  font-size: 2.4rem;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

const Contacts = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "contact" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                phone
                email
                time
              }
            }
          }
        }
      }
      timeIcon: file(name: { eq: "time" }) {
        publicURL
      }
      emailIcon: file(name: { eq: "email" }) {
        publicURL
      }
      phoneIcon: file(name: { eq: "phone" }) {
        publicURL
      }
    }
  `);

  const {
    phone,
    email,
    time,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const { timeIcon, emailIcon, phoneIcon } = data;

  return (
    <Layout>
      <SEO title="Contacts" />
      <Section>
        <H1>Contact information</H1>

        <ContactInfo>
          <Flex>
            <StyledIcon>
              <img src={phoneIcon.publicURL} alt="Phone icon" />
            </StyledIcon>
            <Text>{phone}</Text>
          </Flex>
          <Flex>
            <StyledIcon>
              <img src={emailIcon.publicURL} alt="Email icon" />
            </StyledIcon>
            <Text>{email}</Text>
          </Flex>
          <Flex>
            <StyledIcon>
              <img src={timeIcon.publicURL} alt="Clock icon" />
            </StyledIcon>
            <Text>{time}</Text>
          </Flex>
        </ContactInfo>
      </Section>
    </Layout>
  );
};
export default Contacts;
